import { createAction } from 'typesafe-actions';
import { createActionTypePrefixFormat } from '../common';
import { StateOverviewOrder } from '../stateOverview/types';

const typePrefixFormat = createActionTypePrefixFormat('state');

/* ------------- action creators ------------- */
export const setStateOverviewOrder = createAction(
    typePrefixFormat('SET_STATE_OVERVIEW_ORDER'),
    (order: StateOverviewOrder) => ({
        order,
    }),
)();

export type valueActions = ReturnType<
    | typeof setStateOverviewOrder
>;
