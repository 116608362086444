import { PersistConfig, persistReducer } from 'redux-persist';
import { createReducer, getType } from 'typesafe-actions';

import { ActionTypes, Order, OrderCheck, Pagination } from '@lib/domain/order';
import { OrderActions } from '@lib/state/_actions';
import { produce } from 'immer';
import storage from '@lib/state/storage';

import {
    OrderState,
    CreateOrderResponse,
    OrderUserInfo,
    OrderUpsell,
} from './types';

const stateKey = 'order';

/* ------------- Initial State ------------- */
const INITIAL_STATE: OrderState = {
    orders: [] as Order[],
    orderPost: undefined,
    currentOrder: undefined,
    orderInfo: undefined,
    isNew: false,
    checkedPosts: {} as Record<string, Record<ActionTypes, number>>,
    orderIDs: [],
    pagination: {
        total: 0,
        page_index: 1,
    },
    merged: false,
    checkedList: [],
    channel: undefined,
    user: null,
    loading: false,
    isOrderCreated: false,
};

/* ------------- Reducers ------------- */
const createOrder = (
    state: OrderState,
    {
        payload: { orderInfo, isNew = false },
    }: ReturnType<typeof OrderActions.createOrder>,
): OrderState =>
    produce(state, (draft) => {
        draft.orderInfo = orderInfo;
        draft.isNew = isNew;
    });

const addOrderIdNotLoggedIn = (
    state: OrderState,
    {
        payload: { orderId, merged },
    }: ReturnType<typeof OrderActions.addOrderIdNotLoggedIn>,
): OrderState =>
    produce(state, (draft) => {
        draft.isNew = true;
        if (typeof orderId === 'string') {
            if (
                !draft?.orderIDs?.length ||
                draft.orderIDs.indexOf(orderId) < 0
            ) {
                draft.orderIDs = [orderId, ...draft.orderIDs];
            }
        } else if (orderId.length > 0 && merged) {
            draft.orderIDs = [...draft.orderIDs, ...orderId];
        } else {
            draft.orderIDs = orderId;
        }
        if (typeof merged === 'boolean') {
            draft.merged = merged;
        }
    });

const getOrders = (
    state: OrderState,
    {
        payload: { pagination, data },
    }: ReturnType<typeof OrderActions.getOrders>,
): OrderState =>
    produce(state, (draft) => {
        draft.pagination = pagination;
        draft.orders =
            state.orders && state.orders.length > 0 && data.length === 0
                ? [...state.orders]
                : data;
    });

const checkOrder = (
    state: OrderState,
    { payload: { checkedList } }: ReturnType<typeof OrderActions.checkOrder>,
): OrderState =>
    produce(state, (draft) => {
        draft.checkedList = checkedList;
    });

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
    [getType(OrderActions.createOrder)]: createOrder,
    [getType(OrderActions.addOrderIdNotLoggedIn)]: addOrderIdNotLoggedIn,
    [getType(OrderActions.getOrders)]: getOrders,
    [getType(OrderActions.checkOrder)]: checkOrder,
});

const persistConfig: PersistConfig<OrderState> = {
    // version: 1.1,
    key: stateKey,
    storage,
    blacklist: ['checkedList', 'checkedPosts', 'loading', 'isOrderCreated'],
};

const persistedReducer = persistReducer<OrderState, OrderActions.OrderActions>(
    persistConfig,
    reducer,
);

/* ------------- Selectors ------------- */
// use any for root state for now because of Dependency cycle error
// TODO: resolve dependency cycle error
// eslint-disable-next-line
const getReducerState = (state: any): OrderState => state[stateKey];

const selectors = {
    getOrder: (state: OrderState): Order[] => state.orders,
    getOrderIds: (state: OrderState): string[] => state.orderIDs,
    getOrderInfo: (state: OrderState): CreateOrderResponse | undefined =>
        state.orderInfo,
    getIsNew: (state: OrderState): boolean => state.isNew,
    getCheckedList: (state: OrderState): OrderCheck[] => state.checkedList,
    getcheckedPosts: (
        state: OrderState,
    ): Record<string, Record<ActionTypes, number>> => state.checkedPosts,
    getPagination: (state: OrderState): Pagination => state.pagination,
    getChannel: (state: OrderState): string | undefined => state.channel,
    getOrderUserInfo: (state: OrderState): OrderUserInfo | null => state.user,
    getLoading: (state: OrderState): boolean => state.loading,
    getIsOrderCreated: (state: OrderState): boolean => state.isOrderCreated,
};

/* ------------- Export ------------- */
const reducerObject = {
    // default export
    selectors,

    INITIAL_STATE,

    stateKey,
    getReducerState,
    reducer: persistedReducer,
};

export default reducerObject;
