import { createAction } from 'typesafe-actions';
import { CreateOrderResponse, ListOrderRespone } from '@lib/state/Order/types';
import { OrderCheck } from '@lib/domain/order';
import { createActionTypePrefixFormat } from '../common';

const typePrefixFormat = createActionTypePrefixFormat('User');

/* ------------- action creators ------------- */
export const getOrders = createAction(
    typePrefixFormat('GET_ORDERS'),
    (orders: ListOrderRespone) => ({
        ...orders,
    }), // payload creator
)();

export const createOrder = createAction(
    typePrefixFormat('CREATE_ORDER'),
    (order: CreateOrderResponse, isNew?: boolean) => ({
        orderInfo: order,
        isNew,
    }),
)();

export const addOrderIdNotLoggedIn = createAction(
    typePrefixFormat('ADD_ORDER_ID_NOT_LOGGED_IN'),
    (orderId: string | string[], merged?: boolean) => ({
        orderId,
        merged,
    }),
)();

export const checkOrder = createAction(
    typePrefixFormat('CHECK_ORDER'),
    (checkedList: OrderCheck[]) => ({ checkedList }),
)();

export type OrderActions = ReturnType<typeof getOrders> &
    ReturnType<typeof createOrder> &
    ReturnType<typeof addOrderIdNotLoggedIn> &
    ReturnType<typeof checkOrder>;
