import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

interface OProps {
    getItem: Function;
    setItem: Function;
    removeItem: Function;
}
// eslint-disable-next-line
const createNoopStorage = (): any => {
    return {
        getItem(): Promise<null> {
            return Promise.resolve(null);
        },
        setItem(_key: string, value: string): Promise<string> {
            return Promise.resolve(value);
        },
        removeItem(): Promise<void> {
            return Promise.resolve();
        },
    };
};

const storage =
    typeof window !== 'undefined'
        ? createWebStorage('local')
        : createNoopStorage();

export default storage;
