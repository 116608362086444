import {
    IStateRepository,
    SearchStateUserRequest,
    SearchStateUserResponse,
} from 'lib/domain/state/interface';

import { HTTPService } from '@lib/infra/HTTPService/HTTPService';

interface Dependencies {
    httpService: HTTPService;
}

export class StateRepository implements IStateRepository {
    private httpService: HTTPService;

    constructor({ httpService }: Dependencies) {
        this.httpService = httpService;
    }

    async searchUser(
        req: SearchStateUserRequest,
    ): Promise<SearchStateUserResponse> {
        const url = '/tiktok/users';
        const res = await this.httpService.post({
            url,
            data: req,
        });
        const { data } = res;
        return data;
    }
}
