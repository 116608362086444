import { useDispatch, useSelector } from 'react-redux';
import { CurrencyThunks } from '@lib/state/thunks';
import { AppThunkDispatch } from '@lib/state/types';
import { CurrencyReducer } from '@lib/state/reducers';
import { ConvertCurrency } from '@lib/state/Currency/types';
import { useEffect } from 'react';

/**
 *
 * Check if client currency exist
 * If it does not exist, fetch data from third party library
 *
 */
export const useCurrency = (): ConvertCurrency => {
    const dispatch = useDispatch<AppThunkDispatch>();

    const clientCurrency = useSelector((state) =>
        CurrencyReducer.selectors.getClientCurrency(
            CurrencyReducer.getReducerState(state),
        ),
    );

    const converterValue = useSelector((state) =>
        CurrencyReducer.selectors.getConverterValue(
            CurrencyReducer.getReducerState(state),
        ),
    );
    const countryCode = useSelector((state) =>
        CurrencyReducer.selectors.getCountryCode(
            CurrencyReducer.getReducerState(state),
        ),
    );

    useEffect(() => {
        if (!clientCurrency) {
            (async (): Promise<void> => {
                try {
                    await dispatch(CurrencyThunks.convertCurrency());
                } catch (error) {
                    // eslint-disable-next-line
                    console.log('get currency base error >>> ', error);
                }
            })();
        }
    }, [clientCurrency, dispatch]);

    return {
        clientCurrency,
        converterValue,
        countryCode,
    };
};
