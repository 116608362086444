/* eslint no-underscore-dangle: "error" */
import axios, { AxiosRequestConfig } from 'axios';
import { API_CONFIG } from '@lib/constants';
import { TypedEvent } from '@lib/helpers/event';

import { AuthRequest, FailureResponse, NonAuthRequest } from './types';
import { serviceErrorHandler } from './error';
import { HTTPStatusCode, UnauthorizedEvent, hmacRequest } from './helpers';

export interface HTTPService {
    post: NonAuthRequest;
    get: NonAuthRequest;
    del: NonAuthRequest;
    put: NonAuthRequest;
    authGet: AuthRequest;
    authPost: AuthRequest;
    authDel: AuthRequest;
    authPut: AuthRequest;
    getWithoutHmac: NonAuthRequest;
    // eslint-disable-next-line
    isFailureResponse: (arg: any) => arg is FailureResponse;
    unauthorizedEvent: UnauthorizedEvent;
}

/**
 * add user token to request header
 *
 * @param {Options} options - url Endpoint
 * @param {UserToken} userToken - user token string
 * @returns {Options}
 */
export const withUserToken = (
    options: AxiosRequestConfig,
    token?: string,
): AxiosRequestConfig => {
    if (!token) {
        return options;
    }

    return {
        ...options,
        headers: {
            ...options.headers,
            authorization: `Bearer ${token}`,
        },
    };
};

// HTTP service factory
const httpService = (): HTTPService => {
    const instance = axios.create({
        baseURL: API_CONFIG.HOST,
        timeout: API_CONFIG.timeout,
    });

    const unauthorizedEvent = new TypedEvent<HTTPStatusCode.UNAUTHORIZED>();

    const post: NonAuthRequest = serviceErrorHandler(
        async ({ url, data, options, isHmac = true }) => {
            const reqOptions = options;
            if (isHmac) {
                hmacRequest(options, data);
            }
            const res = await instance.post(url, data, reqOptions);

            return res;
        },
        unauthorizedEvent,
    );

    const get: NonAuthRequest = serviceErrorHandler(
        async ({ url, data, options }) => {
            let reqOptions = hmacRequest(options);
            // `params` are the URL parameters to be sent with the request
            // Must be a plain object or a URLSearchParams object
            if (data) {
                reqOptions = {
                    ...options,

                    params: data,
                };
            }

            const res = await instance.get(url, reqOptions);
            return res;
        },
        unauthorizedEvent,
    );

    const getWithoutHmac: NonAuthRequest = serviceErrorHandler(
        async ({ url, data, options }) => {
            let reqOptions = options;
            // `params` are the URL parameters to be sent with the request
            // Must be a plain object or a URLSearchParams object
            if (data) {
                reqOptions = {
                    ...options,

                    params: data,
                };
            }

            const res = await instance.get(url, reqOptions);
            return res;
        },
        unauthorizedEvent,
    );

    const del: NonAuthRequest = serviceErrorHandler(
        async ({ url, options }) => {
            const reqOptions = hmacRequest(options);
            const res = await instance.delete(url, reqOptions);

            return res;
        },
        unauthorizedEvent,
    );

    const put: NonAuthRequest = serviceErrorHandler(
        async ({ url, data, options }) => {
            const reqOptions = hmacRequest(options);
            const res = await instance.put(url, data, reqOptions);

            return res;
        },
        unauthorizedEvent,
    );

    const authGet: AuthRequest = serviceErrorHandler(
        ({ url, token, data, options = {} }) =>
            get({ url, data, options: withUserToken(options, token) }),
        unauthorizedEvent,
    );

    const authPost: AuthRequest = serviceErrorHandler(
        ({ url, token, data, options = {} }) =>
            post({ url, data, options: withUserToken(options, token) }),
        unauthorizedEvent,
    );

    const authDel: AuthRequest = serviceErrorHandler(
        ({ url, token, options = {} }) =>
            del({ url, options: withUserToken(options, token) }),
        unauthorizedEvent,
    );

    const authPut: AuthRequest = serviceErrorHandler(
        ({ url, token, data, options = {} }) =>
            put({ url, data, options: withUserToken(options, token) }),
        unauthorizedEvent,
    );

    /**
     * API Failure Response type guard
     *
     * @param {*} arg any
     * @returns {arg is FailureResponse}
     */
    // eslint-disable-next-line
    const isFailureResponse = (arg: any): arg is FailureResponse => {
        return arg.message !== undefined;
    };

    return {
        post,
        get,
        del,
        put,
        authGet,
        authPost,
        authDel,
        authPut,
        isFailureResponse,
        unauthorizedEvent,
        getWithoutHmac,
    };
};

export default httpService;
