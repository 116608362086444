import { getDomainNumber } from '@lib/utils/common';
import Stripe from 'stripe';
import { STRIPE_SECRET } from '@constants/STRIPE';
import {
    CreateStripeCheckoutPayload,
    StripeWebhookPayload,
    StripeCustomer,
} from '@lib/domain/payment/stripe';
import { HTTPService } from '@lib/infra/HTTPService/HTTPService';
import { CURRENCY, GetExchangeRateResponse } from '@lib/domain/currency';
import APP_CONFIG from '@constants/APP_CONFIG';

interface Dependencies {
    httpService: HTTPService;
}
export class StripeService {
    private stripe = new Stripe(STRIPE_SECRET, { apiVersion: '2022-11-15' });

    private httpService: HTTPService;

    constructor({ httpService }: Dependencies) {
        this.httpService = httpService;
    }

    async postStripeWebhook(payload: StripeWebhookPayload): Promise<any> {
        const url = '/payment/stripe/webhook';

        const res: { data: any } = await this.httpService.post({
            url,
            data: payload,
        });
        const { data } = res;
        return data;
    }

    async getStripeCustomer(
        email: string,
        order_id: string,
    ): Promise<StripeCustomer> {
        try {
            const url = '/payment/stripe/customer';
            const res: { data: any } = await this.httpService.post({
                url,
                data: {
                    email,
                    order_id,
                },
            });
            const { data } = res;
            return data;
        } catch (error) {
            throw new Error(error);
        }
    }
    async getEXchangeRate(
        currency: string,
        compact: string = 'ultra',
    ): Promise<GetExchangeRateResponse> {
        const res = await this.httpService.get({
            url: '',
            data: {
                apiKey: APP_CONFIG.EXCHANGE_RATE_API_KEY!,
                compact,
                q: currency,
            },
            options: {
                baseURL: 'https://api.currconv.com/api/v7/convert',
            },
        });
        return res.data;
    }

    async getCheckoutSessionUrl({
        order: { summary, orders },
        siteUrl,
    }: CreateStripeCheckoutPayload): Promise<string> {
        try {
            const domain = getDomainNumber(
                (orders && orders[0]?.domain) || summary.domain,
            );
            let exchangeRate = 1;
            if (summary.currency && summary.currency !== 'USD') {
                const currency_key = `USD_${summary.currency}` as CURRENCY;
                const res = await this.getEXchangeRate(currency_key);
                exchangeRate = res[currency_key];
            }
            const total = Math.round(
                parseFloat(summary.total) * exchangeRate * 100,
            );
            // const customer: StripeCustomer = await this.getStripeCustomer(summary.payer_email_address, summary.id);
            const session = await this.stripe.checkout.sessions.create({
                line_items: [
                    {
                        price_data: {
                            currency: summary?.currency
                                ? summary.currency
                                : 'USD',
                            unit_amount: total,
                            product_data: {
                                name: summary.desc,
                            },
                        },
                        quantity: 1,
                    },
                ],
                mode: 'payment',
                success_url: `${siteUrl}/payment/success?domain=${domain}&brand_type=${summary.brand_type}`,
                cancel_url: `${siteUrl}/payment/cancel`,
                client_reference_id: summary.id,
                // customer: customer.stripe_id
            });
            return session.url;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Get order error', error);
            throw error;
        }
    }
}
