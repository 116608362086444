import { PersistConfig, persistReducer } from 'redux-persist';
import { createReducer, getType } from 'typesafe-actions';

import { stateOverview } from '@lib/state/_actions';
import { produce } from 'immer';
import storage from '@lib/state/storage';

import {
    StateReducer,
    StateOverviewOrder,
} from '@lib/state/stateOverview/types';

const stateKey = 'state';

/* ------------- Initial State ------------- */
const INITIAL_STATE: StateReducer = {
    overviewOrder: {
        word_blog_post: {
            quantity: 0,
            price: 0,
            discount: 0,
        },
        word_seo_blog_post: {
            quantity: 0,
            price: 0,
            discount: 0,
        },
        social_post_100: {
            quantity: 0,
            price: 0,
            discount: 0,
        },
        social_post_300: {
            quantity: 0,
            price: 0,
            discount: 0,
        },
    },
};

/* ------------- Reducers ------------- */

const setStateOverviewOrder = (
    state: StateReducer,
    {
        payload: { order },
    }: ReturnType<typeof stateOverview.setStateOverviewOrder>,
): StateReducer =>
    produce(state, (draft) => {
        draft.overviewOrder = order;
    });


/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
    [getType(stateOverview.setStateOverviewOrder)]: setStateOverviewOrder,
});

const persistConfig: PersistConfig<StateReducer> = {
    key: stateKey,
    storage,
};

const persistedReducer = persistReducer<
    StateReducer,
    stateOverview.valueActions
>(persistConfig, reducer);

/* ------------- Selectors ------------- */
// use any for root state for now because of Dependency cycle error
// TODO: resolve dependency cycle error
// eslint-disable-next-line
const getReducerState = (state: any): StateReducer => state[stateKey];

const selectors = {
    getStateOverviewOrder: (state: StateReducer): StateOverviewOrder =>
        state.overviewOrder,
};

/* ------------- Export ------------- */
const reducerObject = {
    // default export
    selectors,

    INITIAL_STATE,

    stateKey,
    getReducerState,
    reducer: persistedReducer,
};

export default reducerObject;
