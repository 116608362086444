import { DOMAIN_NUMBER } from "@constants/DOMAINS";
import { CURRENCY, CURRENCY_CODE, CurrencyState, Option } from "@lib/domain/currency";

export const numberWithCommas: (value: string | number) => string = (value) =>
    new Intl.NumberFormat().format(Number(value));

export const formatNumber: (value: number | string) => string = (value) => {
    if (typeof value === 'string') {
        return parseFloat(value).toFixed(2);
    }
    return value.toFixed(2);
};

export const formatCurrency: (value: number) => string = (value) => {
    if (value === 0) return value.toString();
    return value?.toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
};

export const CURRENCIES = [
    {
        label: '$ USD',
        value: CURRENCY.USD,
        symbol: '$',
        code: CURRENCY_CODE.USD,
    },
    {
        label: '€ EURO',
        value: CURRENCY.EURO,
        symbol: '€',
        code: CURRENCY_CODE.EURO,
    },
    {
        label: '฿ BAHT',
        value: CURRENCY.BAHT,
        symbol: '฿',
        code: CURRENCY_CODE.BAHT,
    },
    {
        label: '₹ RUPEE ',
        value: CURRENCY.RUPEE,
        symbol: '₹',
        code: CURRENCY_CODE.RUPEE,
    },
    {
        label: '৳ TAKA ',
        value: CURRENCY.TAKA,
        symbol: '৳',
        code: CURRENCY_CODE.TAKA,
    },
]
export const getDomainNumber = (domain?: string) => {
    if (domain.includes('app-staging.streamingpals.co')) {
        return DOMAIN_NUMBER.STREAMINGPALS_STAGING;
    }
    if (domain.includes('streamingpals.co')) {
        return DOMAIN_NUMBER.STREAMINGPALS;
    }
    if (domain.includes('localhost')) {
        return DOMAIN_NUMBER.LOCALHOST;
    }
    if (domain.includes('app-staging.tikrocket.co')) {
        return DOMAIN_NUMBER.TIKROCKET_STAGING;
    }
    if (domain.includes('tikrocket.co')) {
        return DOMAIN_NUMBER.TIKROCKET;
    }
    return '3';
};
