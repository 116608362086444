import { PersistConfig, persistReducer } from 'redux-persist';
import { createReducer, getType } from 'typesafe-actions';

import { ArrayCurrency } from '@lib/domain/currency';
import { CurrencyActions } from '@lib/state/_actions';
import { produce } from 'immer';
import storage from '@lib/state/storage';

import { APP_CONFIG } from '@lib/constants';
import { CurrencyState } from './types';

const stateKey = 'currency';

/* ------------- Initial State ------------- */
const INITIAL_STATE: CurrencyState = {
    currencies: null,
    baseCurrency: APP_CONFIG.BASE_CURRENCY,
    clientCurrency: null,
    converterValue: 1,
    countryCode: null,
};

/* ------------- Reducers ------------- */
const setConvertCurrency = (
    state: CurrencyState,
    {
        payload: { convertCurrency },
    }: ReturnType<typeof CurrencyActions.setConvertCurrency>,
): CurrencyState =>
    produce(state, (draft) => {
        Object.assign(draft, convertCurrency);
    });

const setCurrencies = (
    state: CurrencyState,
    {
        payload: { currencies },
    }: ReturnType<typeof CurrencyActions.setCurrencies>,
): CurrencyState =>
    produce(state, (draft) => {
        draft.currencies = currencies;
    });
/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
    [getType(CurrencyActions.setConvertCurrency)]: setConvertCurrency,
    [getType(CurrencyActions.setCurrencies)]: setCurrencies,
});

const persistConfig: PersistConfig<CurrencyState> = {
    key: stateKey,
    storage,
};

const persistedReducer = persistReducer<
    CurrencyState,
    CurrencyActions.CurrencyActions
>(persistConfig, reducer);

/* ------------- Selectors ------------- */
// use any for root state for now because of Dependency cycle error
// TODO: resolve dependency cycle error
// eslint-disable-next-line
const getReducerState = (state: any): CurrencyState => state[stateKey];

const selectors = {
    getBaseCurrency: (state: CurrencyState): string => state.baseCurrency,
    getClientCurrency: (state: CurrencyState): string =>
        state.clientCurrency || '',
    getConverterValue: (state: CurrencyState): number => state.converterValue,
    getCurrencies: (state: CurrencyState): ArrayCurrency | null =>
        state.currencies,
    getCountryCode: (state: CurrencyState): string | null => state.countryCode,
};

/* ------------- Export ------------- */
const reducerObject = {
    // default export
    selectors,

    INITIAL_STATE,

    stateKey,
    getReducerState,
    reducer: persistedReducer,
};

export default reducerObject;
