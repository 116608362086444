import { AppThunk } from '@lib/state/types';
import { ConvertCurrencyPayload } from '@lib/domain/currency/interface';
import CurrencyReducer from './CurrencyReducer';
import { ConvertCurrency } from './types';
import { CurrencyActions } from '../_actions';

/**
 * convert currency
 *
 * @returns {AppThunk<Promise<void>>}
 */
export const convertCurrency =
    (): AppThunk<Promise<void>> =>
    async (dispatch, getState, container): Promise<void> => {
        const rootState = getState();

        const baseCurrency = CurrencyReducer.selectors.getBaseCurrency(
            CurrencyReducer.getReducerState(rootState),
        );

        const data: ConvertCurrencyPayload = {
            baseCurrency,
        };

        // get currency converter
        const res = await container.cradle.currencyService.convertCurrency(
            data,
        );

        const serializeData: ConvertCurrency = {
            clientCurrency: res.geoplugin_currencyCode,
            converterValue: res.geoplugin_currencyConverter,
            countryCode: res.geoplugin_countryCode,
        };
        // set currency to redux state
        dispatch(CurrencyActions.setConvertCurrency(serializeData));
    };

const CurrencyThunks = {
    convertCurrency,
};

export default CurrencyThunks;
