export enum DOMAIN_NUMBER  {
    STREAMINGPALS= 0,
    STREAMINGPALS_STAGING= 1,
    LOCALHOST= 2,
    STREAMCONTENTPALS = 3,
    TIKROCKET_STAGING= 4,
    TIKROCKET = 5
}

export const DOMAINS: Record<DOMAIN_NUMBER, string> = {
    [DOMAIN_NUMBER.STREAMINGPALS]: 'https://www.app.streamingpals.co/dashboard',
    [DOMAIN_NUMBER.STREAMINGPALS_STAGING]: 'https://www.app-staging.streamingpals.co/dashboard',
    [DOMAIN_NUMBER.LOCALHOST]: 'http://localhost:3000/dashboard',
    [DOMAIN_NUMBER.STREAMCONTENTPALS]: '/',
    [DOMAIN_NUMBER.TIKROCKET_STAGING]: 'https://www.app-staging.tikrocket.co/dashboard',
    [DOMAIN_NUMBER.TIKROCKET]: 'https://www.app.tikrocket.co/dashboard',
};
