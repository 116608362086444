import { useEffect, useState } from 'react';

// Hook
export const useWindowSize = (): { width: number; height: number } => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            const handleResize = (): void => {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            // Add event listener
            window.addEventListener('resize', handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return (): void =>
                window.removeEventListener('resize', handleResize);
        }
        return () => {
            // TODO
        };
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
};
