const APP_CONFIG = {
    // app specific
    displayName: 'nextjs_template',
    prefixReducer: 'linepals',
    QUERY_PAGE_SIZE: 10,
    SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    MAX_AMOUNT: 350,
    BASE_CURRENCY: 'USD',
    SECRET_KEY_HMAC: process.env.NEXT_PUBLIC_SECRET_KEY_HMAC,
    EMAIL_CONTACT: 'recordpalslimited@gmail.com',
    EXCHANGE_RATE_API_KEY: process.env.NEXT_PUBLIC_CONVERT_CURRENCY_API_KEY

};

export default APP_CONFIG;

export const GEOLOCATION_PLUGIN_URL =
    process.env.NEXT_PUBLIC_GEOLOCATION_PLUGIN_URL;
