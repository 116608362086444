/* eslint-disable import/named */
import * as awilix from 'awilix';

import makeHTTPService, {
    HTTPService,
} from '@lib/infra/HTTPService/HTTPService';

import { CurrencyServices } from '@lib/infra/currency/currency.service';
import { IStateRepository } from '@lib/domain/state/interface';
import { StateRepository } from '@lib/infra/state/StateRepository';
import { OrderRepository } from '@lib/infra/order/OrderRepository';
import { IOrderRepository } from '@lib/domain/order/interface';
import { StripeService } from '@lib/infra/Stripe/StripeService';


export interface Cradle {
    // services
    httpService: HTTPService;
    currencyService: CurrencyServices;
    stateRepository: IStateRepository;
    stripeService: StripeService;
    // repositories
    orderRepository: IOrderRepository;

    // apps
}

// Create the container and set the injectionMode to PROXY (which is also the default).
const container = awilix.createContainer<Cradle>();

/* ------------- Infra ------------- */
container
    // services
    .register({
        httpService: awilix.asFunction(makeHTTPService).singleton(),
        currencyService: awilix.asClass(CurrencyServices).singleton(),
        stateRepository: awilix.asClass(StateRepository),
        stripeService: awilix.asClass(StripeService).singleton(),

    })
    // repositories
    .register({
        orderRepository: awilix.asClass(OrderRepository),
    });

/* ------------- App ------------- */

export default container;
