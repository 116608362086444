export interface Currency {
    currencyName: string;
    currencySymbol?: string;
    id: string;
}

export type ArrayCurrency = Map<string, Currency>;

export interface ConvertCurrencyResponse {
    geoplugin_currencyCode: string;
    geoplugin_currencyConverter: number;
    geoplugin_request: string;
    geoplugin_countryCode: string;
    geoplugin_regionName: string;
    geoplugin_regionCode: string;
}
export type Option<T> = {
    label: string
    value: T
    icon?: string
    symbol?: string
    code?: CURRENCY_CODE
}
export enum CURRENCY {
    USD = 'USD_USD',
    EURO = 'USD_EUR',
    BAHT = 'USD_THB',
    RUPEE = 'USD_INR',
    TAKA = 'USD_BDT',
}
export enum CURRENCY_CODE {
    USD = 'USD',
    EURO = 'EUR',
    BAHT = 'THB',
    RUPEE = 'INR',
    TAKA = 'BDT',
}
export type CurrencyState = {
    value: CURRENCY
    exchangeRate: number
    label: string
    symbol: string
    code: CURRENCY_CODE
}
export type GetExchangeRatePayload = {
    apiKey: string
    compact: string
    q: string
}

export type GetExchangeRateResponse = Record<CURRENCY, number>