import { createAction } from 'typesafe-actions';
import { createActionTypePrefixFormat } from '../common';
import { ConvertCurrency, ArrayCurrency } from '../Currency/types';

const typePrefixFormat = createActionTypePrefixFormat('Currency');

/* ------------- action creators ------------- */
export const setConvertCurrency = createAction(
    typePrefixFormat('SET_CONVERT_CURRENCY'),
    (convertCurrency: ConvertCurrency) => ({
        convertCurrency,
    }), // payload creator
)();

export const setCurrencies = createAction(
    typePrefixFormat('SET_CURRENCIES'),
    (currencies: ArrayCurrency) => ({
        currencies,
    }), // payload creator
)();

export type CurrencyActions = ReturnType<
    typeof setConvertCurrency | typeof setCurrencies
>;
