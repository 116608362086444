import 'react-popper-tooltip/dist/styles.css';
import 'nouislider/distribute/nouislider.css';
import 'rc-collapse/assets/index.css';
import 'aos/dist/aos.css';
import 'assets/styles/global.css';

import { AppProps } from 'next/app';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { useRouter } from 'next/router';
import { useStore } from '@lib/state/store';
import { useCurrency } from '@lib/view/hooks/curency';
import Head from '@lib/view/components/layout/Head';
import SpinnerLoading from '@lib/view/components/loading/spinner-loading';

const ComponentWithState: React.FC = () => {
    useCurrency();
    return null;
};

const LoadingComponent: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const { pathname } = useRouter();
    if (pathname === '/') {
        return <div>{children}</div>;
    }
    return <SpinnerLoading />;
};

const AnimationComponent: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    return <div className="translate-loading">{children}</div>;
};

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
    const store = useStore(pageProps.initialReduxState);
    const persistor = persistStore(store, {}, (): void => {
        persistor.persist();
    });
    const { title, description, image } = pageProps;
    return (
        <>
            <Head title={title} description={description} image={image} />

            <Provider store={store}>
                <PersistGate
                    loading={
                        <LoadingComponent>
                            <Component {...pageProps} />
                        </LoadingComponent>
                    }
                    persistor={persistor}
                >
                    <AnimationComponent>
                        <Component {...pageProps} />
                    </AnimationComponent>
                    <ComponentWithState />
                </PersistGate>
            </Provider>
        </>
    );
};

export default App;
