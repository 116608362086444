import { AppThunk } from '@lib/state/types';
import { OrderActions } from '@lib/state/_actions';
import { OrderPost, OrderInfo } from '@lib/domain/order';
import { ListOrderRespone, GetListOrderPayload } from '@lib/state/Order/types';
import { OrderReducer } from '@lib/state/reducers';

/**
 * Create Order
 * @param {OrderPost} orders
 * @return {AppThunk<Promise<OrderInfo>>}
 */
export const createOrder =
    (orders: OrderPost): AppThunk<Promise<OrderInfo>> =>
    async (dispatch, _getState, container): Promise<OrderInfo> => {
        const { channel } = OrderReducer.getReducerState(_getState());
        // create order
        const res: OrderInfo =
            await container.cradle.orderRepository.createOrder({
                ...orders,
                channel,
            });
        // set order to redux state
        // dispatch(OrderActions.addOrderIdNotLoggedIn(res.id));
        dispatch(OrderActions.createOrder(res, true));

        return res;
    };

/**
 * Get list order with token
 *
 * @returns {AppThunk<Promise<void>>}
 */

export const getListOrder =
    (pageInfo: GetListOrderPayload): AppThunk<Promise<void>> =>
    async (dispatch, _getState, container): Promise<void> => {
        try {
            // get list order
            const orders: ListOrderRespone =
                await container.cradle.orderRepository.getListOrder(pageInfo);
            // set order list to redux state
            dispatch(OrderActions.getOrders(orders));
        } catch (error) {
            const { message } = error as Error;
            if (
                typeof message === 'string' &&
                message.includes(
                    ' it must be a 12-byte input or a 24-character hex string',
                )
            ) {
                const orderIds = OrderReducer.selectors.getOrderIds(
                    OrderReducer.getReducerState(_getState()),
                );
                const newOrders: string[] = [];
                orderIds.forEach((id) => {
                    if (id.length >= 24) {
                        newOrders.push(id);
                    }
                });
                dispatch(OrderActions.addOrderIdNotLoggedIn(newOrders));
            }
            throw error;
        }
    };

const OrderThunks = {
    createOrder,
    getListOrder,
};

export default OrderThunks;
