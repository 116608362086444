import { IOrderRepository } from '@lib/domain/order/interface';
import { OrderPost, CheckOrderResponse } from '@lib/domain/order';
import {
    CreateOrderResponse,
    ListOrderRespone,
    GetListOrderPayload,
    CheckOrderRequest,
} from '@lib/state/Order/types';
import { HTTPService } from '@lib/infra/HTTPService/HTTPService';
import qs from 'qs';

interface Dependencies {
    httpService: HTTPService;
}

export class OrderRepository implements IOrderRepository {
    private httpService: HTTPService;

    constructor({ httpService }: Dependencies) {
        this.httpService = httpService;
    }

    async createOrder(orders: OrderPost): Promise<CreateOrderResponse> {
        const url = '/order/';
        const res: { data: CreateOrderResponse } = await this.httpService.post({
            url,
            data: orders,
        });
        const { data } = res;
        return data;
    }

    async getListOrder(
        pageInfo: GetListOrderPayload,
    ): Promise<ListOrderRespone> {
        const url = 'order/';

        const { order_summary_ids, ...rest } = pageInfo;
        const queryIDs = order_summary_ids
            ? qs.stringify(
                  { order_summary_ids },
                  {
                      indices: false,
                  },
              )
            : null;
        const queryEncode = queryIDs
            ? `${qs.stringify(rest)}&${queryIDs}`
            : qs.stringify(rest);
        const res = await this.httpService.get({
            url: `${url}?${queryEncode}`,
        });

        const orders = res.data;
        return orders;
    }

    async checkOrder(payload: CheckOrderRequest): Promise<CheckOrderResponse> {
        const url = 'order/check_order';
        const res: { data: CheckOrderResponse } = await this.httpService.post({
            url,
            data: payload,
        });
        const { data } = res;
        return data;
    }
}
