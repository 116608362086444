import {
    ConvertCurrencyPayload,
    ICurrencyServices,
} from '@lib/domain/currency/interface';
import { ConvertCurrencyResponse } from '@lib/domain/currency';
import { HTTPService } from '@lib/infra/HTTPService/HTTPService';
import { GEOLOCATION_PLUGIN_URL } from '@lib/constants/APP_CONFIG';

interface Dependencies {
    httpService: HTTPService;
}

export class CurrencyServices implements ICurrencyServices {
    private httpService: HTTPService;

    constructor({ httpService }: Dependencies) {
        this.httpService = httpService;
    }

    async convertCurrency(
        data: ConvertCurrencyPayload,
    ): Promise<ConvertCurrencyResponse> {
        const res = await this.httpService.getWithoutHmac({
            url: '',
            data,
            options: {
                baseURL: GEOLOCATION_PLUGIN_URL,
            },
        });

        return res.data;
    }
}
